import { IButtonProps } from './type';
import styled from 'styled-components';

const Button = styled.button<IButtonProps>`
  background: #ffffff;
  color: #000000;
  border: 1px solid #5b5d6b;
  border-radius: 5px;
  font: normal normal normal 14px Lato;
  padding: 12px 16px;
  @media (min-width: 1536px) {
    font: normal normal normal 18px/24px Lato;
    padding: 12px 16px;
  }
  ${(props) =>
    props.rounded === 'l' &&
    `
    border-radius: 5px 0 0 5px;
    `}
  ${(props) =>
    props.width &&
    `
    width: ${props.width};
    `}
  ${(props) =>
    props.height &&
    `
    height: ${props.height};
    `}
  ${(props) =>
    props.rounded === 'l' &&
    `
    border-radius: 5px 0 0 5px;
    `}
  ${(props) =>
    props.rounded === 'r' &&
    `
    border-radius: 0 5px 5px 0;
    `}
  ${(props) =>
    props.theme === 'secondary' &&
    `
      background: #2e3192;
      color: #ffffff;

    `}
    ${(props) =>
    props.theme === 'black' &&
    `
      background: #000000;
      color: #ffffff;

    `}
  ${(props) =>
    props.theme === 'danger' &&
    `
      border: 0px;
      background: #c82333;
      color: #ffffff;
    `}
  ${(props) =>
    props.text === 'uppercase' &&
    `
      text-transform: uppercase;
    `}
    ${(props) =>
    props.text === 'lowercase' &&
    `
      text-transform: lowercase;
    `}
    ${(props) =>
    props.size === 'middle' &&
    `
      font-size: 20px;
    `}
    ${(props) =>
    props.wrap === 'full' &&
    `
      width: 100%;
      text-align: center;
    `}
    ${(props) =>
    props.wrap === 'fit' &&
    `
      width: fit-content;
      text-align: center;
    `}
    ${(props) =>
    props.disabled &&
    `
      background: #2e3192;
      color: #ffffff;
      opacity: 0.3;
    `}
`;

export default {
  Button,
};
