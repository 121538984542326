import S from './styled';
import { IButtonProps } from './type';
import { ComponentProps, FC, forwardRef } from 'react';

type Props = Omit<ComponentProps<any>, ''> & IButtonProps;

const Button = forwardRef<HTMLButtonElement, Props>(
  ({ children, height, onClick, type, radius, width, className, text, theme, size, wrap, isLoading, ...props }) => (
    <S.Button
      onClick={onClick}
      wrap={wrap}
      width={width}
      theme={theme}
      height={height}
      text={text}
      type={type}
      className={`flex justify-center truncate items-center py-3 px-4 hover:ring-4 hover:outline-none outline-none ${className} ${
        isLoading ? 'spinner' : ''
      } `}
      size={size}
      {...props}
    >
      {children}
    </S.Button>
  )
);
export default Button;
